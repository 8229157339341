import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from "react";
import { classNameJoin } from "utils/lib";
import Icon from "./Icon";

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  suffix?: IconProp;
  small?: boolean;
  inputClassName?: string;
  suffixClassName?: string;
  onSuffixClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
  onEnter?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      small,
      inputClassName,
      suffixClassName,
      suffix,
      onFocus,
      onBlur,
      onKeyUp,
      onSuffixClick,
      onEnter,
      ...props
    },
    ref
  ) => {
    const [focus, setFocus] = useState<boolean>(false);

    // onFocus
    const focusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      ev.preventDefault();
      onFocus && onFocus(ev);
      setFocus(true);
    };

    // onBlur
    const blurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      ev.preventDefault();
      onBlur && onBlur(ev);
      setFocus(false);
    };

    const keyupHandler = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key.toUpperCase() === "ENTER") {
        onEnter && onEnter(ev);
      }

      onKeyUp && onKeyUp(ev);
    };

    return (
      <label
        className={classNameJoin([
          "inline-flex items-center rounded-md border transition-all overflow-hidden duration-200 bg-white",
          className,
          small ? "h-[32px]" : "h-[40px]",
          focus ? "border-primary-900" : "border-secondary-200",
        ])}
      >
        <input
          ref={ref}
          className={classNameJoin([
            "outline-none border-none px-4 w-full h-full disabled:bg-secondary-50 disabled:text-secondary-500",
            small ? "text-sm" : "text-[15px]",
            inputClassName,
          ])}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onKeyUp={keyupHandler}
          {...props}
        />

        {suffix && (
          <div
            className={classNameJoin([
              "text-center mr-3 cursor-pointer text-secondary-700",
              suffixClassName,
            ])}
            onClick={onSuffixClick}
          >
            <Icon icon={suffix} />
          </div>
        )}
      </label>
    );
  }
);

export default Input;
