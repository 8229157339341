import React, { useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";

type TSliderDirection = "BOTTOM_TOP" | "LEFT_RIGHT" | "RIGHT_LEFT";

interface IStyleProps {
  toggle: boolean;
}

interface IContentStyleProps {
  toggle: boolean;
  direction: TSliderDirection;
}

interface IStackSliderParameter {
  direction?: TSliderDirection;
  items: { label: string; [key: string]: any }[];
  onPick: (props: any) => void;
}

interface IStackSliderContext {
  show: (props: IStackSliderParameter) => void;
  close: () => void;
}

export const StackSliderContext = React.createContext<IStackSliderContext>(
  null!
);

const StackSliderProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [direction, setDirection] = useState<TSliderDirection>("BOTTOM_TOP");
  const [stack, setStack] = useState<any[]>([]);
  const clickEvent = useRef<any>(null);

  const show = useCallback(
    ({ direction = "BOTTOM_TOP", items, onPick }: IStackSliderParameter) => {
      setStack(items);
      setDirection(direction);
      setToggle(true);
      clickEvent.current = onPick;
    },
    []
  );

  const close = useCallback(() => {
    setToggle(false);
    clickEvent.current = null;
  }, []);

  const pickHandler = useCallback(
    (props: any) => {
      clickEvent.current && clickEvent.current(props);
      close();
    },
    [close]
  );

  const value = {
    close,
    show,
  };

  return (
    <StackSliderContext.Provider value={value}>
      {children}
      <Wrapper toggle={toggle}>
        <Background onClick={close} />
        <Content toggle={toggle} direction={direction}>
          <ul>
            {stack.map((props, i) => {
              return (
                <li
                  key={i}
                  className="py-3 px-4"
                  onClick={() => pickHandler(props)}
                >
                  {props.label}
                </li>
              );
            })}
          </ul>
        </Content>
      </Wrapper>
    </StackSliderContext.Provider>
  );
};

const Wrapper = styled.div<IStyleProps>`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s;

  ${(props) =>
    props.toggle
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

const Content = styled.div<IContentStyleProps>`
  transition: all 0.3s;
  padding: 16px;
  z-index: 10;
  position: absolute;
  width: 100%;

  ul {
    background-color: white;
    padding: 4px 0;
    border-radius: 8px;
  }

  ${({ direction, toggle }) => {
    switch (direction) {
      case "BOTTOM_TOP":
        return css`
          bottom: -100%;

          ${toggle &&
          css`
            bottom: 0px;
          `}
        `;
      case "LEFT_RIGHT":
        return css``;
      case "RIGHT_LEFT":
        return css``;
      default:
        return css``;
    }
  }}
`;

export default StackSliderProvider;
