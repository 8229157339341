import { useMemo } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";

export interface IProps {
  page: number;
  size?: number;
  count: number;
  blockCount?: number;
  onPage: (page: number) => void;
  onSize?: (size: number) => void;
}

const Pagination = ({
  page = 1,
  size = 10,
  count = 0,
  blockCount = 10,
  onPage,
}: IProps) => {
  const pageHandler = (page: number) => {
    onPage && onPage(page);
  };

  const { pages, currentBlock, blocks, pageCount } = useMemo(() => {
    const pages = Math.ceil(count / size);
    const blocks = Math.ceil(pages / blockCount);
    const currentBlock = Math.ceil(page / blockCount);
    const currentFullPage = currentBlock * blockCount;
    const pageCount = currentFullPage > pages ? pages % blockCount : blockCount;

    return { pages, pageCount, currentBlock, blocks };
  }, [page, size, count, blockCount]);

  if (page > pages) return null;

  return (
    <div className="inline-flex items-center">
      <Arrow disabled={currentBlock === 1} onClick={() => pageHandler(1)}>
        <Icon icon="angles-left" fontSize={14} />
      </Arrow>
      <Arrow
        className="ml-1"
        disabled={currentBlock === 1}
        onClick={() => pageHandler(blockCount * (currentBlock - 2) + 1)}
      >
        <Icon icon="angle-left" fontSize={14} />
      </Arrow>

      <ul className="flex items-center px-2">
        {Array.from(new Array(pageCount)).map((_, i) => {
          const num = i + 1 + blockCount * (currentBlock - 1);

          return (
            <Page
              key={i}
              active={page === num}
              onClick={() => pageHandler(num)}
            >
              {num}
            </Page>
          );
        })}
      </ul>

      <Arrow
        className="mr-1"
        disabled={currentBlock === blocks}
        onClick={() => pageHandler(blockCount * currentBlock + 1)}
      >
        <Icon icon="angle-right" fontSize={14} />
      </Arrow>
      <Arrow
        disabled={currentBlock === blocks}
        onClick={() => pageHandler(pages)}
      >
        <Icon icon="angles-right" fontSize={14} />
      </Arrow>
    </div>
  );
};

const Arrow = styled.button<{ disabled?: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #292929;

  &:disabled {
    cursor: default;
    color: #bebebe;
  }
`;

const Page = styled.li<{ active?: boolean }>`
  cursor: pointer;
  padding: 2px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5a5a5a;

  & + & {
    margin-left: 4px;
  }

  ${(props) =>
    props.active &&
    css`
      color: #eb2773;
      background-color: #fde9f1;
    `}
`;

export default Pagination;
