import { useState } from "react";
import { classNameJoin } from "utils/lib";

interface IProps {
  menu: { label: string; value: string }[];
  onActiveMenu: (value: string) => void;
}

const Dropdown = ({
  children,
  menu,
  onActiveMenu,
}: React.PropsWithChildren<IProps>) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const show = () => {
    setToggle(true);
    window.addEventListener("mouseup", hide);
  };

  const hide = () => {
    setToggle(false);
    window.removeEventListener("mouseup", hide);
  };

  return (
    <div className="relative select-none">
      <div>
        <button onClick={show}>{children}</button>
      </div>

      <ul
        className={classNameJoin([
          "absolute py-1.5 bg-white box-shadow rounded transition-all z-40 right-1",
          toggle
            ? "opacity-100 visible translate-y-1"
            : "opacity-0 invisible -translate-y-3",
        ])}
      >
        {menu.map(({ label, value }, i) => {
          return (
            <li
              className="px-4 py-2 text-[15px] whitespace-nowrap cursor-pointer hover:bg-secondary-50"
              key={i}
              onClick={() => onActiveMenu(value)}
            >
              {label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
