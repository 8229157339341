import { Suspense, useState } from "react";
import { useRoutes } from "react-router-dom";
import styled from "styled-components";
import routes from "./";
import GlobalHeader from "layouts/GlobalHeader";
import GlobalNavigationBar from "layouts/GlobalNavigationBar";

const Fallback = () => <div />;

const Home = () => {
  const [toggle, setToggle] = useState(false);
  const pages = useRoutes(routes);

  return (
    <>
      <GlobalHeader toggle={toggle} onToggle={() => setToggle(!toggle)} />
      <GlobalLayout>
        <GlobalNavigationBar
          toggle={toggle}
          onToggle={() => setToggle(!toggle)}
        />
        <Suspense fallback={<Fallback />}>{pages}</Suspense>
      </GlobalLayout>
    </>
  );
};

const GlobalLayout = styled.main`
  position: relative;
  height: calc(100% - 56px);
  overflow: hidden;
`;

export default Home;
