import {
  IDashboard,
  IDateRange,
  IReport,
  IReportData,
  IUpload,
} from "interfaces/service.interface";
import request, { Response } from "./config";

export const getDashboards = (params: IDateRange) =>
  request.get<Response<IDashboard>>("/dashboards", { params });

export const fileUpload = (data: FormData) =>
  request.post<Response<IUpload[]>>("/files/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getReportCategory = () =>
  request.get<Response<IReport[]>>("/reports/categories");

export const postReport = (data: IReportData) => request.post("/reports", data);
