import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

// Dashboard
const DashboardPage = React.lazy(() => import("./dashboard/DashboardPage"));

// Manage Order
const ManageOrderListPage = React.lazy(() => import("./manage/order/ListPage"));
const ManageOrderViewPage = React.lazy(() => import("./manage/order/ViewPage"));

// Manage Post
const ManageBoardListPage = React.lazy(() => import("./manage/post/ListPage"));
const ManageBoardViewPage = React.lazy(() => import("./manage/post/ViewPage"));
const ManageBoardWritePage = React.lazy(
  () => import("./manage/post/WritePage")
);

// Manage Guest
const ManageFollowerListPage = React.lazy(
  () => import("./manage/follower/ListPage")
);

// Chat
const ChatListPage = React.lazy(() => import("./chat/ListPage"));
const ChatViewPage = React.lazy(() => import("./chat/ViewPage"));

// Broadcast
const BroadcastDetailPage = React.lazy(() => import("./broadcast/DetailPage"));
const BroadcastEditPage = React.lazy(() => import("./broadcast/EditPage"));
const BroadcastPage = React.lazy(() => import("./broadcast/ListPage"));
const BroadcastResultPage = React.lazy(() => import("./broadcast/ResultPage"));
const BroadcastViewPage = React.lazy(() => import("./broadcast/ViewPage"));
const BroadcastReservationPage = React.lazy(
  () => import("./broadcast/ReservationPage")
);
const BroadcastProductPage = React.lazy(
  () => import("./broadcast/ProductPage")
);

// Point
const PointPage = React.lazy(() => import("./point/PointPage"));
const PointChargePage = React.lazy(() => import("./point/ChargePage"));
const PointUsagePage = React.lazy(() => import("./point/UsagePage"));
const PointChargingPage = React.lazy(() => import("./point/ChargingPage"));
const PointSuccessPage = React.lazy(() => import("./point/SuccessPage"));
const PointFailPage = React.lazy(() => import("./point/FailPage"));

// Profile
const ProfilePage = React.lazy(() => import("./profile/ProfilePage"));
const ProfileEditPage = React.lazy(() => import("./profile/ProfileEditPage"));
const CategoryEditPage = React.lazy(() => import("./profile/CategoryEditPage"));

// Toss
export const TossPaymentCheckingPage = React.lazy(
  () => import("./toss/PaymentChecking")
);

// User
export const LoginPage = React.lazy(() => import("./user/LoginPage"));
export const SignupPage = React.lazy(() => import("./user/SignupPage"));
export const CompletePage = React.lazy(() => import("./user/CompletePage"));
export const LogoutPage = React.lazy(() => import("./user/LogoutPage"));
export const FindEmailPage = React.lazy(() => import("./user/FindEmailPage"));
export const FindPasswordPage = React.lazy(
  () => import("./user/FindPasswordPage")
);

const routes: RouteObject[] = [
  {
    path: "dashboard",
    element: <Outlet />,
    children: [{ index: true, element: <DashboardPage /> }],
  },
  {
    path: "manage",
    element: <Outlet />,
    children: [
      {
        path: "order",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ManageOrderListPage />,
          },
          {
            path: "view",
            element: <ManageOrderViewPage />,
          },
        ],
      },
      {
        path: "post",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ManageBoardListPage />,
          },
          {
            path: "write",
            element: <ManageBoardWritePage />,
          },
          {
            path: ":id",
            element: <ManageBoardViewPage />,
          },
          {
            path: "edit/:id",
            element: <ManageBoardWritePage />,
          },
        ],
      },
      {
        path: "follower",
        element: <ManageFollowerListPage />,
      },
    ],
  },
  {
    path: "chat",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ChatListPage />,
      },
      {
        path: ":id",
        element: <ChatViewPage />,
      },
    ],
  },
  {
    path: "broadcast",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <BroadcastPage />,
      },
      {
        path: ":id",
        element: <BroadcastViewPage />,
      },
      {
        path: "reservation",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <BroadcastReservationPage />,
          },
          {
            path: ":id",
            element: <BroadcastReservationPage />,
          },
        ],
      },

      {
        path: "result/:id",
        element: <BroadcastResultPage />,
      },
      {
        path: "detail/:id",
        element: <BroadcastDetailPage />,
      },
      {
        path: "product/:id",
        element: <BroadcastProductPage />,
      },
      {
        path: "edit/:id",
        element: <BroadcastEditPage />,
      },
    ],
  },
  {
    path: "point",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <PointPage />,
      },
      {
        path: "charge",
        element: <PointChargePage />,
      },
      {
        path: "used",
        element: <PointUsagePage />,
      },
      {
        path: "charging",
        element: <PointChargingPage />,
      },
      {
        path: "success",
        element: <PointSuccessPage />,
      },
      {
        path: "fail",
        element: <PointFailPage />,
      },
    ],
  },
  {
    path: "profile",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ProfilePage />,
      },
      {
        path: "edit",
        element: <ProfileEditPage />,
      },
      {
        path: "category",
        element: <CategoryEditPage />,
      },
    ],
  },
];

export default routes;
