import { getBroadcastNotificationSetting } from "api/broadcast.request";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";

const useNotificationSetting = () => {
  const user = useRecoilValue(userState);
  const { data, isFetching, refetch } = useQuery(
    ["notification-setting", user],
    () => {
      if (!user) return null;
      return getBroadcastNotificationSetting().then(({ data }) => {
        if (!data.data) return null;
        return data.data;
      });
    },
    {
      initialData: [],
    }
  );

  const onReload = () => {
    refetch();
  };

  return { data, isLoading: isFetching, onReload };
};

export default useNotificationSetting;
