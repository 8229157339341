import React, { useState, useEffect, useRef, useCallback } from "react";
import { getPoints } from "api/point.request";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";

export interface IPointContext {
  point: number;
  loading: boolean;
  error: boolean;
  refetch: () => void;
}

export const PointContext = React.createContext<IPointContext>(null!);

const PointProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const user = useRecoilValue(userState);
  const mounted = useRef<boolean>(false);
  const [point, setPoint] = useState<number>(0);
  const [loading, setLoaindg] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const refetch = useCallback(async () => {
    if (!user) return;

    setLoaindg(true);

    try {
      const { data } = await getPoints();
      if (!data.data) return;
      setPoint(data.data.remainingPoint);
      mounted.current = true;
    } catch (err: any) {
      setError(true);
    } finally {
      setLoaindg(false);
    }
  }, [user]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const value = {
    point,
    loading,
    error,
    refetch,
  };

  return (
    <PointContext.Provider value={value}>{children}</PointContext.Provider>
  );
};

export default PointProvider;
