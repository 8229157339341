import { classNameJoin } from "utils/lib";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  frame?: boolean;
}

const Button = ({
  children,
  className,
  frame,
  ...props
}: React.PropsWithChildren<IProps>) => {
  return (
    <button
      className={classNameJoin([
        "text-sm py-0.5 px-2.5 whitespace-nowrap rounded-2xl border border-primary-900 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:border-secondary-50 transition-all duration-300",
        frame ? "bg-transparent text-primary-900" : "bg-primary-900 text-white",
        className,
      ])}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
