import { classNameJoin } from "utils/lib";
import Progress from "../Progress";
import { Options } from "./table.interface";

interface IProps {
  data?: { [key in string]: any }[] | null;
  loading?: boolean;
  render: (((item: any, i: number) => React.ReactNode) | undefined)[];
  column: string[];
  align: string[];
  overflow: boolean[];
  options?: Options;
  rowClass?: (data: any) => string;
  onRowClick: (
    d: any,
    i: number,
    ev: React.MouseEvent<HTMLTableRowElement>
  ) => void;
}

const TBody = ({
  align,
  column,
  data,
  loading,
  options,
  overflow,
  render,
  rowClass,
  onRowClick,
}: IProps) => {
  if (loading) {
    return (
      <tbody>
        <tr>
          <td className="h-[180px] text-center" colSpan={align.length}>
            <Progress>데이터를 가져오고 있습니다.</Progress>
          </td>
        </tr>
      </tbody>
    );
  }

  if (options?.error) {
    return (
      <tbody>
        <tr>
          <td className="h-[180px] text-center" colSpan={align.length}>
            {options.errorText}
          </td>
        </tr>
      </tbody>
    );
  }

  if (!data || !data.length) {
    return (
      <tbody>
        <tr>
          <td className="h-[180px] text-center" colSpan={align.length}>
            <p>
              {options && options.emptyText
                ? options.emptyText
                : "데이터가 없습니다."}
            </p>
            {options && options.emptyDescription && (
              <p
                className="text-secondary-900 text-sm mt-1"
                dangerouslySetInnerHTML={{ __html: options.emptyDescription }}
              ></p>
            )}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className="overflow-x-auto">
      {data.map((d, i) => {
        return (
          <tr
            key={i}
            className={classNameJoin([
              "border-t border-secondary-200 cursor-pointer hover:bg-neutral-50",
              rowClass && rowClass(d),
            ])}
            onClick={(ev) => onRowClick(d, i, ev)}
          >
            {column.map((c, j) => {
              return (
                <td
                  className={classNameJoin([
                    "py-2 px-3",
                    align[j],
                    overflow[j]
                      ? "overflow-ellipsis overflow-hidden whitespace-nowrap"
                      : "",
                  ])}
                  key={c}
                >
                  {render[j] ? render[j]!(d, i) : d[c] || "-"}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
