import { INotification } from "interfaces/user.interface";
import { classNameJoin } from "utils/lib";

interface IProps extends INotification {
  onRead: (props: INotification) => void;
}

const NotificationItem = ({
  message,
  unreadCount,
  createdAt,
  onRead,
  ...props
}: IProps) => {
  return (
    <li
      className="cursor-pointer"
      onClick={(ev) => {
        ev.stopPropagation();
        onRead({ ...props, message, unreadCount, createdAt });
      }}
    >
      <div
        className={classNameJoin([
          "p-4",
          unreadCount === 0 ? "" : "bg-primary-50",
        ])}
      >
        <p className="max-h-[64px] break-all overflow-hidden [display:-webkit-box] [-webkit-box-orient:vertical] [-webkit-line-clamp:3]">
          {message}
        </p>
        <span className="w-full block text-right text-secondary-900 mt-1">
          {createdAt}
        </span>
      </div>
      <div className="h-[1px] bg-secondary-100 w-[90%] mx-auto"></div>
    </li>
  );
};

export default NotificationItem;
