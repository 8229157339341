import { useCallback, useEffect, useState } from "react";

const TABLET = 768;
const LARGE = 1024;

const useResponsive = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [tablet, setTablet] = useState<boolean>(window.innerWidth <= TABLET);
  const [large, setLarge] = useState<boolean>(window.innerWidth <= LARGE);

  const resizeHandler = useCallback(
    (ev: UIEvent) => {
      const window = ev.target as Window;
      const next = window.innerWidth;
      setWidth(next);

      if (!tablet && next <= TABLET) {
        setTablet(true);
      } else if (tablet && next > TABLET) {
        setTablet(false);
      } else if (!large && next <= LARGE) {
        setLarge(true);
      } else if (large && next > LARGE) {
        setLarge(false);
      }
    },
    [tablet, large]
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return function cleanup() {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  return {
    width,
    tablet,
    large,
  };
};

export default useResponsive;
