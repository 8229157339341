import { useContext } from "react";
import { PointContext } from "contexts/PointProvider";
import { numbericComma } from "utils/lib";

const useMyPoint = () => {
  const { point, loading, error, refetch } = useContext(PointContext);

  return {
    point,
    loading,
    commaPoint: numbericComma(point || 0),
    error,
    refetch,
  };
};

export default useMyPoint;
