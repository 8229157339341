import React, { useState } from "react";
import { classNameJoin } from "utils/lib";
import Input, { IProps } from "./Input";

const Password = ({ suffixClassName, onChange, ...props }: IProps) => {
  const [type, setType] = useState<"password" | "text">("password");

  const changeTypeHandler = () => {
    const next = type === "password" ? "text" : "password";
    setType(next);
  };

  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(ev.target.value)) {
      return;
    }

    onChange && onChange(ev);
  };

  return (
    <Input
      {...props}
      suffixClassName={classNameJoin([
        suffixClassName,
        type === "text" ? "text-primary-700" : "",
      ])}
      type={type}
      suffix={type === "password" ? "eye" : "eye-slash"}
      onSuffixClick={changeTypeHandler}
      onChange={changeHandler}
    />
  );
};

export default Password;
