import {
  IChargingHistory,
  IPointRatio,
  IPoints,
  IPointsPostData,
  IUsageHistory,
} from "interfaces/point.interface";
import {
  IPagination,
  ITossPaymentData,
  ITossPaymentResult,
} from "interfaces/service.interface";
import request, { Response, TodoResponse } from "./config";

export const getPoints = () => request.get<Response<IPoints>>("/points");

export const getPointRatio = () =>
  request.get<Response<IPointRatio[]>>("/points/policy");

export const postPoints = (data: IPointsPostData) =>
  request.post("/points", data);

export const getUsageHistory = (params: IPagination) =>
  request.get<TodoResponse<IUsageHistory[]>>("/points/usage-history", {
    params,
  });

export const getChargingHistory = (params: IPagination) =>
  request.get<TodoResponse<IChargingHistory[]>>("/points/charging-history", {
    params,
  });

export const tossPayment = (data: ITossPaymentData) =>
  request.post<Response<ITossPaymentResult>>("/points/payment", data);
