import { IMe } from "interfaces/user.interface";
import { atom } from "recoil";

export const isLoginState = atom<boolean>({
  key: "isLoginState",
  default: false,
});

export const accessTokenState = atom<string | null>({
  key: "accessTokenState",
  default: null,
});

export const userState = atom<IMe | null>({
  key: "userState",
  default: null,
});

export const myPoint = atom<number | null>({
  key: "myPoint",
  default: null,
});
