import { useState, useCallback } from "react";

const useConfirm = (initialContent = "", initialWhen = true) => {
  const [confirmState, setConfirmState] = useState({
    visible: false,
    options: {},
    onConfirm: () => {},
    content: initialContent,
    when: initialWhen,
  });

  const show = useCallback((options: any) => {
    setConfirmState((prevState) => ({
      ...prevState,
      visible: true,
      options,
      onConfirm: options.onConfirm,
    }));
  }, []);

  const confirm = useCallback(() => {
    confirmState.onConfirm();
    setConfirmState((prevState) => ({
      ...prevState,
      visible: false,
      options: {},
      onConfirm: () => {},
    }));
  }, [confirmState]);

  const cancel = useCallback(() => {
    setConfirmState((prevState) => ({
      ...prevState,
      visible: false,
      options: {},
      onConfirm: () => {},
    }));
  }, []);

  return {
    show,
    confirmState,
    confirm,
    cancel,
  };
};

export default useConfirm;
