import { classNameJoin } from "utils/lib";

interface IProps {
  className?: string;
  limitLength: number;
  contentLength: number;
}

const ContentLimit = ({ className, contentLength, limitLength }: IProps) => {
  return (
    <div className={classNameJoin(["inline-block text-inherit", className])}>
      <span
        className={
          contentLength >= limitLength
            ? "text-primary-900"
            : "text-secondary-900"
        }
      >
        {contentLength}
      </span>
      <span>/</span>
      <span className="text-black">{limitLength}자</span>
    </div>
  );
};

export default ContentLimit;
