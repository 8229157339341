/**
 * 방송상태 수정 (2022.08.09)
finish,                 ->  endOfBroadcast
incompletedItem,        ->  incompletedBroadcast
proceeding,             ->  onAir
expected,               ->  toBeBroadcast
incompletedItemExpired  ->  incompletedBroadcastExpired
                        ->  readyToBroadcast (추가)
 */

import { TChargingStatus } from "interfaces/point.interface";

export type TLiveStatus =
  | "endOfBroadcast"
  | "incompletedBroadcast"
  | "onAir"
  | "toBeBroadcast"
  | "incompletedBroadcastExpired"
  | "readyToBroadcast";

export type TNotificationSettingStatus =
  | "AllSubscribers"
  | "Recent3MonthsSubscribers"
  | "Recent6MonthsSubscribers"
  | "Recent12MonthsSubscribers";

export const dayOfweekKO = ["일", "월", "화", "수", "목", "금", "토"];

export const liveStatusKey: TLiveStatus[] = [
  "incompletedBroadcast",
  "incompletedBroadcastExpired",
  "toBeBroadcast",
  "readyToBroadcast",
  "onAir",
  "endOfBroadcast",
];

export const liveStatus: { [key in TLiveStatus]: string } = {
  endOfBroadcast: "종료",
  incompletedBroadcast: "미등록",
  onAir: "진행중",
  toBeBroadcast: "예정",
  incompletedBroadcastExpired: "등록만료",
  readyToBroadcast: "준비중",
};

export const chargeStatus: { [key in TChargingStatus]: string } = {
  COMPLETION: "결제완료",
  CANCEL: "결제취소",
  WAITING: "결제대기",
  REFUND: "환불",
};

export const notificationSettingStatus: {
  [key in TNotificationSettingStatus]: string;
} = {
  AllSubscribers: "전체",
  Recent3MonthsSubscribers: "최근 3개월 참여자",
  Recent6MonthsSubscribers: "최근 6개월 참여자",
  Recent12MonthsSubscribers: "최근 12개월 참여자",
};

export const maximumByte = 5242880;

export const MILLISECONDS = 1000;
export const SECONDS = 60 * MILLISECONDS;
export const MINUTE = 60 * SECONDS;
export const HOUR = 24 * MINUTE;
export const DAY = MILLISECONDS * SECONDS * MINUTE * HOUR;
