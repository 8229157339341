import React, { useState } from "react";
import { IReportMeta } from "interfaces/service.interface";
import ReportPopup from "components/ReportPopup";

export const ReportPopupContext = React.createContext<{
  show: (suspect: string, meta: IReportMeta) => void;
}>(null!);

const ReportPopupProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [suspect, setSuspect] = useState<string | undefined>();
  const [meta, setMeta] = useState<IReportMeta | null>(null);

  const show = (suspect: string, meta: IReportMeta) => {
    setToggle(true);
    setSuspect(suspect);
    setMeta(meta);
  };

  const close = () => {
    setToggle(false);
    setSuspect(undefined);
    setMeta(null);
  };

  const value = {
    show,
  };

  return (
    <ReportPopupContext.Provider value={value}>
      {children}
      <ReportPopup
        suspect={suspect}
        meta={meta}
        toggle={toggle}
        onClose={close}
      />
    </ReportPopupContext.Provider>
  );
};

export default ReportPopupProvider;
