import {
  IBroadcast,
  IBroadcastDetailPostData,
  IBroadcastMaxPeople,
  IBroadcastNotificationSetting,
  IBroadcastParams,
  IBroadcastPatchData,
  IBroadcastPostData,
  IBroadcastPostResult,
  IBroadcastProductPostData,
  IBroadcastTime,
  IBroadcastView,
  IProduct,
} from "interfaces/broadcast.interface";
import request, { Response, TodoResponse } from "./config";

export const getBroadcasts = (params: IBroadcastParams) =>
  request.get<TodoResponse<IBroadcast[]>>("/broadcasts", { params });

export const postBroadcast = (data: IBroadcastPostData) =>
  request.post<Response<IBroadcastPostResult>>(`/broadcasts`, data);

export const getBroadcast = (broadcastId: string) =>
  request.get<Response<IBroadcastView>>(`/broadcasts/${broadcastId}`);

export const postBroadcastDetail = (
  broadcastId: string,
  data: IBroadcastDetailPostData
) => request.post(`/broadcasts/${broadcastId}/posts`, data);

export const putBroadcast = (broadcastId: string, data: IBroadcastPatchData) =>
  request.put(`/broadcasts/${broadcastId}`, data);

export const cancelBroadcast = (broadcastId: string) =>
  request.delete(`/broadcasts/${broadcastId}`);

export const getBroadcastProduct = (broadcastId: string) =>
  request.get<TodoResponse<IProduct[]>>(`/broadcasts/${broadcastId}/products`);

export const postBroadcastProduct = (
  broadcastId: string,
  data: IBroadcastProductPostData
) => request.post(`/broadcasts/${broadcastId}/products`, data);

export const putBroadcastProduct = (
  broadcastId: string,
  data: IBroadcastProductPostData
) => request.put(`/broadcasts/${broadcastId}/products`, data);

export const getBroadcastTimes = (params: { date: string }) =>
  request.get<Response<IBroadcastTime[]>>("broadcasts/times", { params });

export const getBroadcastMaxPeople = () =>
  request.get<Response<IBroadcastMaxPeople[]>>("/broadcasts/max-people");

export const getBroadcastNotificationSetting = () =>
  request.get<Response<IBroadcastNotificationSetting[]>>("/broadcasts/notification-settings");
